import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	margin: 0 0 0.6em 0;

	&.disabled {
		pointer-events: none;

		.progress-wrapper {
			opacity: 0.5;
		}
	}

	.progress-wrapper {
		position: relative;
		background-color: #d9ceb8;
		padding: 0.5em 1em;
		color: ${vars.colorWhite};
		overflow: hidden;
		border-radius: 5px;

		&__content {
			z-index: 2;
			position: relative;
			display: flex;
			gap: 1em;
			justify-content: space-between;
			height: 1.6em;
			overflow: hidden;

			.scroller {
				position: absolute;
				top: -4px;
				left: 50%;
				transform: translateX(-50%);
				transition: transform 0.3s linear, left 0.2s ease-in-out;

				&--decimal {
					left: 45%;
				}

				&__item {
					font-size: 22px;
				}
			}

			.disabled-scroller-placeholder {
				font-size: 22px;
				position: absolute;
				top: -4px;
				left: 50%;
				transform: translateX(-50%);
			}

			.add-button {
				.button {
					.button-icon-before {
						position: relative;

						&::before {
							position: absolute;
							top: 0;
							left: 0;
							content: url('/theme/images/product-select-horizontal/subtract-hover.svg');
							opacity: 0;
							transition: opacity 0.3s ease-in-out;
						}

						&:hover {
							&::before {
								opacity: 1;
							}
						}
					}
				}
			}
			.subtract-button {
				.button {
					.button-icon-before {
						position: relative;

						&::before {
							position: absolute;
							top: 0;
							left: 0;
							content: url('/theme/images/product-select-horizontal/add-hover.svg');
							opacity: 0;
							transition: opacity 0.3s ease-in-out;
						}

						&:hover {
							&::before {
								opacity: 1;
							}
						}
					}
				}
			}
		}

		&__background {
			z-index: 1;
			position: absolute;
			top: 0;
			left: -100%;
			height: 100%;
			width: 100%;
			background: transparent linear-gradient(269deg, #d0bc94 0%, #b9944a 100%)
				0% 0% no-repeat padding-box;
			transition: transform 0.3s ease-in-out, background 0.5s ease-in-out;

			// used after beacuse transitions not working for gradient background
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: '';
				opacity: 0;
				transition: opacity 0.4s 0.6s ease-in-out;
			}

			&.bg-green {
				&:after {
					opacity: 1;
					background: transparent
						linear-gradient(180deg, #c4d5ac 0%, #819367 100%) 0% 0% no-repeat
						padding-box;
				}
			}
		}
	}
`;
